import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pricePerMeter } from '../../../../../../shared/utils/helpers/hosmanVars';

import EstimationReview from './components/EstimationReview'
import NoValuationError from './components/NoValuationError'
import { D_CircleInfo } from 'hosman-material';

const EstimatedPrices = ({ sale, valuation, setValuation }) => {
  if (!valuation) return <NoValuationError />;

  const roundTo = (price, round) => {
    return Math.round(price/round)*round
  }

  return (
    <div className='flex flex-col gap-8 md:border md:border-ih-purple-30 md:p-8 rounded-3xl text-center text-ih-indigo bg-' data-testid='EstimatedPrices'>
      <div className='flex flex-col items-center gap-4'>
        <div className='flex flex-col'>
          <p className='text-4xl font-semibold text-ih-blue'>{roundTo(valuation.sale_price, 1000).toLocaleString()} € </p>
          <p className='font-medium'>soit {pricePerMeter(valuation.sale_price, sale.property)}</p>
        </div>

        <div className='flex items-center gap-2 px-3 py-2 rounded-xl bg-ih-purple-10 border border-ih-purple-50'>
          <FontAwesomeIcon icon={D_CircleInfo} className='text-ih-indigo' size='sm' />
          <p className='text-sm font-medium'>Fiabilité de l'algorithme : {roundTo(valuation.confidence, 10)}%</p>
        </div>
      </div>

      <div className='flex justify-center md:justify-around gap-5 md:w-3/4 md:mx-auto'>
        <div className='flex flex-col whitespace-nowrap'>
          <p className='text-xl font-semibold'>{roundTo(valuation.lower_price, 1000).toLocaleString()} € </p>
          <p className='font-medium'>soit {pricePerMeter(valuation.lower_price, sale.property)}</p>
          <p className='text-ih-indigo-50 mt-1'>Estimation basse</p>
        </div>

        <div className='border-l w-min mx-auto'></div>

        <div className='flex flex-col whitespace-nowrap'>
          <p className='text-xl font-semibold'>{roundTo(valuation.upper_price, 1000).toLocaleString()} € </p>
          <p className='font-medium'>soit {pricePerMeter(valuation.upper_price, sale.property)}</p>
          <p className='text-ih-indigo-50 mt-1'>Estimation haute</p>
        </div>
      </div>

      <EstimationReview valuation={valuation} setValuation={setValuation} />
    </div>
  );
}

export default EstimatedPrices;
