import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { S_LocationDot } from 'hosman-material';
import TextInput from './TextInput';
import { initMapScript, fetchAddressData, extractAddress } from '../service/googleMapsService';

const AddressInput = ({ name = 'address', value, label, onChange, errors = {}, placeholder, listPlacement = 'top-10' }) => {
  const [stateValue, setStateValue] = useState(value || '');
  const [predictions, setPredictions] = useState([]);
  const [displayPredictions, setDisplayPredictions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, [stateValue]);

  useEffect(() => {
    setStateValue(value || '')
  }, [value]);

  useEffect(() => {
    // Close prediction list when clicking outside input
    window.onclick = () => {
      setDisplayPredictions(false)
    };
  }, []);

  useEffect(() => {
    // Focus on main input on page load
    if (inputRef.current && !stateValue) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [predictions, selectedIndex]);

  function initAutocomplete() {
    if (stateValue.length < 2) setPredictions([])

    const displaySuggestions = function (predictions, status) {
      if (document.activeElement === inputRef.current) setDisplayPredictions(true)
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) return;

      setPredictions(predictions.map(prediction => prediction.description))
    };

    const service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions({ input: stateValue, types: ['address'], componentRestrictions: { country: 'fr' } }, displaySuggestions);
  }

  const selectOption = async (prediction) => {
    setDisplayPredictions(false)
    const result = await fetchAddressData(prediction)
    const addressObj = extractAddress(name, result)
    setStateValue(addressObj[name]);
    onChange(addressObj);
  }

  const handleChange = (e) => {
    setStateValue(e.target.value)
    onChange({ [name]: e.target.value })
  }

  const handleKeyDown = (event) => {
    if (!displayPredictions || predictions.length === 0) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex(prevIndex =>
          prevIndex < predictions.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex(prevIndex =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case 'Enter':
        event.preventDefault();
        if (selectedIndex >= 0) {
          selectOption(predictions[selectedIndex]);
        }
        break;
    }
  };

  return (
    <div className='relative' data-testid='AddressInput'>
      <TextInput
        refProp={inputRef}
        name={name}
        label={label}
        value={stateValue}
        onChange={handleChange}
        errors = {errors}
        placeholder={placeholder}
        paddings='pl-8'
        icon={S_LocationDot}
      />

      {displayPredictions && predictions.length > 0 &&
        <ul className={`z-50 absolute w-full bg-white rounded-2xl border border-ih-purple-20 overflow-y-scroll scrollbar-hide mt-2 ${listPlacement}`}>
          {predictions.map((prediction, index) => {
            return (
              <li
                key={prediction}
                onClick={() => selectOption(prediction)}
                className={`text-ih-indigo first:rounded-t-2xl last:rounded-b-2xl hover:bg-ih-purple-5 cursor-pointer select-none relative py-3 px-3 border-b border-b-ih-purple-10 ${index === selectedIndex ? 'bg-ih-purple-5' : ''}`}
              >
                {prediction}
              </li>
            )
          })}
        </ul>
      }
    </div>
  );
};

export default AddressInput;

AddressInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  listPlacement: PropTypes.string
};
