import React, { useState, useEffect } from 'react';

import CancelModal from './components/CancelModal';
import StateLabel from './components/StateLabel';
import StateCard from './components/StateCard';

import { S_Check, S_HourglassHalf, S_Xmark } from 'hosman-material';
import { TimeFeed, FadeIn } from 'hosman-material';

const Visits = ({ visits, sale }) => {
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (visits.length > 0) {
      let payload = []

      visits.map(visit => {
        const event = {
          id: visit.id,
          stateLabel: <StateLabel visit={visit} />,
          icon: icon(visit),
          iconColor: iconColor(visit),
          content: (<StateCard visit={visit} sale={sale} />),
        };

        payload.push(event)
      })

      setEvents(payload)
    }
  }, [visits]);

  const icon = (visit) => {
    switch (visit.state) {
      case 'proposed':
      case 'confirmed':
        return S_HourglassHalf;
      case 'done':
        return S_Check;
      case 'cancelled_visitor':
      case 'cancelled_owner':
      case 'cancelled_guide':
      case 'cancelled_terminated':
        return S_Xmark;
    }
  }

  const iconColor = (visit) => {
    switch (visit.state) {
      case 'proposed':
      case 'confirmed':
        return 'bg-ih-blue';
      case 'done':
        return 'bg-ih-green';
      case 'cancelled_visitor':
      case 'cancelled_owner':
      case 'cancelled_guide':
      case 'cancelled_terminated':
        return 'bg-ih-red';
    }
  }

  return (
    <FadeIn>
      <TimeFeed events={events} />

      <CancelModal expert={sale.expert} />
    </FadeIn>
  )
}

export default Visits;
