import React from 'react';
import PropTypes from 'prop-types';

import TitleBanner from '../../components/TitleBanner';
import SimpleExperience from './components/SimpleExperience'
import RealEstatePortals from './components/RealEstatePortals'
import BuyerAlertBase from './components/BuyerAlertBase'
import SponsorshipCard from '../../../../../napoleon/components/Marketing/SponsorshipCard'
import TrustpilotReviews from './components/TrustpilotReviews'

const WhyHosman = ({ estimationFolder }) => {
  return (
    <div className='flex flex-col' data-testid='WhyHosman'>
      <TitleBanner number='06' title='Pourquoi Hosman ?' />

      <div className='flex flex-col gap-12'>
        <SimpleExperience />

        <RealEstatePortals sale={estimationFolder.sale} />

        <BuyerAlertBase />

        <TrustpilotReviews expertId={estimationFolder.sale.expert.id} />

        <div className='md:mx-8'>
          <SponsorshipCard avatarSrc={estimationFolder.sale.expert?.user?.profile_pic_url} link={`/ev/ventes/${estimationFolder.sale.id}/parrainage`} />
        </div>
      </div>
    </div>
  );
}

export default WhyHosman;

WhyHosman.propTypes = {
  estimationFolder: PropTypes.object.isRequired,
};
