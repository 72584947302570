import React from 'react';

import { SelectInput } from 'hosman-material';

const reasons = [
  { id: 1, value: 'visitor_emergency', name: "J'ai un imprévu" },
  { id: 2, value: 'location', name: "L'emplacement du bien ne me convient finalement pas" },
  { id: 3, value: 'found', name: "J'ai trouvé un bien qui me convient mieux" },
  { id: 4, value: 'dislike', name: 'Ce bien a une caractéristique rédhibitoire pour moi' },
  { id: 5, value: 'partner', name: 'Le bien ne plaît pas à mon conjoint' }
]

const ReasonInput = ({ selectedReason, setSelectedReason, errors, setErrors }) => {
  const handleSelect = (reason) => {
    setSelectedReason(reason);
    setErrors((prev) => ({ ...prev, cancel_reason: null }))
  }

  return (
    <SelectInput
      name='cancel_reason'
      options={reasons}
      selectedOption={selectedReason}
      handleChange={(reason) => handleSelect(reason)}
      errors={errors}
    />
  )
}

export default ReasonInput;
