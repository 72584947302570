import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from 'hosman-material';
import { S_Xmark, D_CircleInfo } from 'hosman-material';

import { resetErrorAndForm } from '../../../../../../reducers/_Pages/sponsorshipModuleSlice';

export const ModalError = () => {
  const { error } = useSelector((state) => state.sponsorshipModule);
  const dispatch = useDispatch();

  return (
    <Modal isOpen={!!error} closeModal={() => dispatch(resetErrorAndForm())}>
      <div className='w-full md:w-96 p-6'>
        <div className='flex flex-col items-center justify-center gap-7'>
          <div className='flex flex-col items-center justify-center gap-6'>
            <div className='flex flex-row items-center justify-center w-11 h-11 rounded-xl shadow-ih-3'>
              <FontAwesomeIcon icon={S_Xmark} size='lg' className='text-ih-red' />
            </div>

            <p className='h3'>
              Oups... il semblerait que ce filleul ne soit pas éligible au parrainage
            </p>
          </div>

          <p className='text-sm text-indigo'> <FontAwesomeIcon icon={D_CircleInfo} className='text-ih-blue'/> Motif : {error?.message?.error} </p>

          <p className='text-sm text-indigo'>
            Vous avez des questions ? Contactez-nous à cette adresse mail : <span className='font-semibold text-underline'>parrainage@hosma.co</span>
          </p>

          <Button label="C'est compris" onClick={() => dispatch(resetErrorAndForm())} />
        </div>
      </div>
    </Modal>
  )
};
