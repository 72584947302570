import React, { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import VisitCancelForm from './components/VisitCancelForm';
import Authentication from './components/Authentication';

const VisitCancelModule = ({ visit, setVisit }) => {
  const containerRef = useRef();

  return (
    <div className='ih-container' ref={containerRef} data-testid='VisitCancelModule'>
      <AnimatePresence mode='wait'>
        {visit.id ? (
          <motion.div
            key='VisitCancelForm'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <VisitCancelForm visit={visit} />
          </motion.div>
        ) : (
          <motion.div
            key='Authentication'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Authentication setVisit={setVisit} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default VisitCancelModule;
