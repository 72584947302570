import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AvatarMenu from './components/AvatarMenu';
import MenuButton from './components/MenuButton';
import { HosmanLogo } from 'hosman-material';
import { S_Xmark, S_BarsStaggered } from 'hosman-material';

const MobileBanner = ({ sidebarOpen, setSidebarOpen }) => {
  // Lower logo size on very small phones
  const logoWidth = window.innerWidth < 350 ? 100 : 150

  return (
    <div className='flex justify-between items-center sticky top-0 z-40 lg:hidden p-3 bg-ih-indigo'>
      <Link to='/'><HosmanLogo width={logoWidth} rgbColor='#ffffff' /></Link>

      <div className='flex items-center space-x-5'>
        <AvatarMenu />

        {sidebarOpen && <MenuButton setSidebarOpen={() => setSidebarOpen(false)} icon={S_Xmark} />}

        {!sidebarOpen && <MenuButton setSidebarOpen={() => setSidebarOpen(true)} icon={S_BarsStaggered} />}
      </div>
    </div>
  );
};

export default MobileBanner;

// PropTypes
MobileBanner.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func
};
