import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextInput, PhoneNumberInput } from 'hosman-material';

import { updateGodsonForm, updateFormErrors } from '../../../../../../reducers/_Pages/sponsorshipModuleSlice';

const Inputs = () => {
  const dispatch = useDispatch();
  const { formErrors, godsonForm } = useSelector((state) => state.sponsorshipModule);

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(updateFormErrors({ ...formErrors, [name]: null }));
    dispatch(updateGodsonForm({ ...godsonForm, [name]: value }));
  };

  return (
    <>
      <div className='flex flex-col md:flex-row gap-3 w-full'>
        <TextInput
          name='first_name'
          label='Prénom'
          placeholder='Pierre'
          onChange={handleChange}
          value={godsonForm.first_name}
          errors={formErrors}
        />

        <TextInput
          name='last_name'
          label='Nom'
          placeholder='Mondaint'
          onChange={handleChange}
          value={godsonForm.last_name}
          errors={formErrors}
        />
      </div>

      <div className='flex flex-col gap-3 w-full'>
        <div className='w-full'>
          <TextInput
            name='email'
            label='Adresse mail'
            placeholder='pierremondaint@gmail.com'
            onChange={handleChange}
            value={godsonForm.email}
            errors={formErrors}
          />
        </div>

        <div className='w-full'>
          <PhoneNumberInput
            name='phone_number'
            onChangePhone={(val) => handleChange({ target: { name: 'phone_number', value: val }})}
            onChangeCountryCode={(val) => handleChange({ target: { name: 'phone_country_code', value: val }})}
            phoneValue={godsonForm.phone_number}
            countryCodeValue={godsonForm.phone_country_code}
            errors={formErrors}
            placeholder='06 77 88 29 63'
          />
        </div>
      </div>
    </>
  )
};

export default Inputs;
