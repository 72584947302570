import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import React from 'react';
import { toast } from 'react-hot-toast';
import { S_Check, S_Xmark } from 'hosman-material';

import VISITS from '../../api/ressources/BuyerApp/visits';
import PUBLIC_VISITS from '../../api/ressources/App/visits';

export const getVisits = createAsyncThunk(
  '/getVisits',
  async (params) => {
    const response = await VISITS.getVisits(params)
    return response.data
  }
);

export const cancelVisit = createAsyncThunk(
  '/cancelVisit',
  async ({ visitId, userSlug, params }) => {
    const response = await PUBLIC_VISITS.cancelVisit(visitId, userSlug, params)
    return response.data
  }
);

const initialState = {
  allVisits: [],
  cancelModalVisitId: null,
  visitsState: '',
  visitCancelState: ''
};

export const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    setCancelModalVisitId(state, { payload }) {
      state.cancelModalVisitId = payload;
    },
    resetVisits(state) {
      state.allVisits = initialState.allVisits;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVisits.pending, (state) => {
      state.visitsState = 'pending'
    })
    builder.addCase(getVisits.fulfilled, (state, { payload }) => {
      state.allVisits = payload.results;
      state.visitsState = 'done'
    })
    builder.addCase(cancelVisit.pending, (state, { payload }) => {
      state.visitCancelState = 'pending'
    })
    builder.addCase(cancelVisit.fulfilled, (state, { payload }) => {
      const newPayload = [...state.allVisits];
      const updatedIndex = newPayload.findIndex(visit => visit.id === payload.id);
      newPayload[updatedIndex] = payload;

      state.allVisits = newPayload;
      state.cancelModalVisitId = initialState.cancelModalVisitId;
      state.visitCancelState = ''

      toast.success(<p>La visite a bien été annulée</p>, { icon: S_Check, duration: 3000 })
    })
  },
})

export const { setCancelModalVisitId, resetVisits } = visitsSlice.actions;

export default visitsSlice.reducer;
