import React from 'react';
import { useSelector } from 'react-redux';
import { goodStateOptions, constructionTimeOptions } from './selectOptions';

import { NumberInput, NumberInputStepper, SelectInput, InfoIcon } from 'hosman-material';

const ApartmentInputs = ({ handleInputChange }) => {
  const { estimationData: { mainInfos }, errors} = useSelector((state) => state.estimationFunnel);

  return (
    <div className='flex flex-col gap-6' data-testid='ApartmentInputs'>
      <NumberInput
        name='carrez_area'
        placeholder='0'
        label='Surface (Carrez)'
        onChange={(e) => handleInputChange(e, { integer: true })}
        value={mainInfos.carrez_area}
        sign='m²'
        allowDecimal
        errors={errors}
        autoFocus
      />

      <div className='flex flex-col md:flex-row gap-6 md:gap-3'>
        <div className='flex flex-col gap-2 w-full'>
          <NumberInputStepper
            name='floor'
            placeholder='0'
            label='Étage du bien'
            onChange={handleInputChange}
            value={mainInfos.floor}
            errors={errors}
          />
          <div className='flex items-center gap-2'>
            <InfoIcon bgColor='bg-ih-indigo-30' iconColor='text-ih-indigo-60' />
            <p className='text-ih-indigo-40 text-sm'>Mettre 0 si appartement au rez-de-chaussée.</p>
          </div>
        </div>

        <NumberInputStepper
          name='total_floor'
          placeholder='0'
          label="Nombre d'étages de l'immeuble"
          onChange={handleInputChange}
          value={mainInfos.total_floor}
          errors={errors}
        />
      </div>

      <div className='flex flex-col gap-2'>
        <NumberInputStepper
          name='room_number'
          placeholder='0'
          label='Nombre de pièces'
          onChange={handleInputChange}
          value={mainInfos.room_number}
          errors={errors}
        />
        <div className='flex items-center gap-2'>
          <InfoIcon bgColor='bg-ih-indigo-30' iconColor='text-ih-indigo-60' />
          <p className='text-ih-indigo-40 text-sm'>Ne comptez ni la cuisine, ni les salles de bains, ni les toilettes.</p>
        </div>
      </div>

      <NumberInputStepper
        name='bedroom_number'
        placeholder='0'
        label='Nombre de chambres'
        onChange={handleInputChange}
        value={mainInfos.bedroom_number}
        errors={errors}
      />

      <SelectInput name='construction_time' label='Période de construction'
        options={constructionTimeOptions}
        selectedOption={constructionTimeOptions.find(option => option.value === mainInfos.construction_time)}
        handleChange={(option) => handleInputChange({ target: { name: 'construction_time', value: option.value }})}
        errors={errors}
      />

      <SelectInput name='good_state' label='État général du bien'
        options={goodStateOptions}
        selectedOption={goodStateOptions.find(option => option.value === mainInfos.good_state)}
        handleChange={(option) => handleInputChange({ target: { name: 'good_state', value: option.value }})}
        errors={errors}
      />
    </div>
  )
}

export default ApartmentInputs;
