import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_LocationDot, S_Xmark } from 'hosman-material';
import { TextInput } from 'hosman-material';

const mapApiUrl = 'https://maps.googleapis.com/maps/api/js';

const GeocoderZonesSelect = ({ name, zones, label, onChange, errors = {}, placeholder, listPlacement = 'top-10' }) => {
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [displayPredictions, setDisplayPredictions] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, [inputValue]);

  useEffect(() => {
    // Close prediction list when clicking outside input
    window.onclick = () => {
      setDisplayPredictions(false)
    };
  }, []);

  function initAutocomplete() {
    if (inputValue.length < 2) setPredictions([])

    const displaySuggestions = function (predictions, status) {
      if (document.activeElement === inputRef.current) setDisplayPredictions(true)
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) return;

      // Filter to allow only standard Latin letters, numbers, and common punctuation
      const filteredPredictions = predictions.filter(prediction =>
        /^[\w\s.,!?'"()\-+:;&àâäçéèêëîïôöùûüÿ]+$/.test(prediction.description)
      );

      setPredictions(filteredPredictions.map(prediction => prediction.description))
    };

    const service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions({ input: inputValue, types: ['(regions)'], componentRestrictions: { country: 'fr' } }, displaySuggestions);
  }

  const selectOption = async (prediction) => {
    setDisplayPredictions(false)
    onChange(Array.from(new Set([...zones, prediction])));
    setInputValue('');
  }

  const removeSelectedOption = (e, locationToRemove) => {
    e.stopPropagation();
    const newZones = zones.filter(zone => zone !== locationToRemove);
    onChange(newZones);
    setInputValue('');
  };

  return (
    <div className='relative' data-testid='GeocoderZonesSelect'>
      <TextInput
        refProp={inputRef}
        name={name}
        label={label}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        errors = {errors}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        paddings='pl-8'
      />

      <FontAwesomeIcon icon={S_LocationDot} className={`text-ih-purple-40 absolute left-3 ${label ? 'top-[53px]' : 'top-3'} mt-0.5 text-lg`} />

      {displayPredictions && predictions.length > 0 &&
        <ul className={`z-50 absolute w-full bg-white rounded-2xl border border-ih-purple-20 overflow-y-scroll mt-2 ${listPlacement}`}>
          {predictions.map(prediction => {
            return (
              <li
                key={prediction}
                onClick={() => selectOption(prediction)}
                className='text-ih-indigo first:rounded-t-2xl last:rounded-b-2xl hover:bg-ih-purple-5 cursor-pointer select-none relative py-3 px-3 border-b border-b-ih-purple-10'
              >
                {prediction}
              </li>
            )
          })}
        </ul>
      }

      {zones.length > 0 && (
        <div className='mt-2 flex flex-wrap gap-2'>
          {zones.map((zone, index) => (
            <div key={index}
                  value={zone}
                  className='w-fit rounded-lg flex justify-center items-center gap-2 cursor-pointer px-2 py-1 bg-ih-purple-20 hover:bg-ih-purple-30 text-ih-blue transition-all duration-300 ease-in-out'
                  onClick={(e) => removeSelectedOption(e, zone)}>
              {zone.replace(', France', '')}
              <FontAwesomeIcon icon={S_Xmark} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function initMapScript() {
  if (window.google) {
    return Promise.resolve();
  }

  window.initMap = function() {};
  const src = `${mapApiUrl}?key=${process.env.GOOGLE_API_BROWSER_KEY}&libraries=places&language=fr&callback=initMap`;
  return loadAsyncScript(src);
}

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src
    })
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  })
}

export default GeocoderZonesSelect;

GeocoderZonesSelect.propTypes = {
  name: PropTypes.string,
  zones: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  listPlacement: PropTypes.string
};
