import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { S_CircleCheck } from 'hosman-material';
import { Button } from 'hosman-material';

const NextSteps = () => {
  return (
    <div className='flex flex-col gap-8 bg-ih-purple-5 border border-ih-purple-20 p-5 rounded-3xl' data-testid='NextSteps'>
      <p className='h2 text-ih-indigo text-center'>Prochaines étapes</p>

      <div className='flex flex-col md:flex-row justify-between gap-5'>
        <div className='relative border border-ih-green-60 flex flex-col gap-3 p-5 rounded-3xl w-full md:w-4/5 bg-white'>
          <p className='bg-ih-indigo text-white rounded-full p-3 flex items-center justify-center w-10 h-10'>01</p>
          <p className='text-ih-indigo font-semibold'>Pré-estimation en ligne de votre bien</p>
          <div className='flex items-center gap-2 md:absolute bottom-3'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' />
            <p>Terminé</p>
          </div>
        </div>

        <div className='border-2 border-ih-blue flex flex-col gap-3 p-5 rounded-3xl w-full h-fit bg-white'>
          <p className='bg-ih-indigo text-white rounded-full p-3 flex items-center justify-center w-10 h-10'>02</p>
          <p className='text-ih-indigo font-semibold'>Ajustement du prix selon les spécificités de votre bien</p>
          <p className='text-ih-indigo-60'>Un expert se déplace à votre bien pour affiner cette estimation en prenant en compte toutes les spécificités de votre bien.</p>
        </div>

        <div className='border border-ih-indigo-20 flex flex-col gap-3 p-5 rounded-3xl w-full md:w-4/5 bg-white'>
          <p className='bg-ih-indigo text-white rounded-full p-3 flex items-center justify-center w-10 h-10'>03</p>
          <p className='text-ih-indigo font-semibold'>Mise en vente de votre bien par notre expert</p>
          <p className='text-ih-indigo-60'>Diffusion sur + de 30 sites, ainsi qu'à notre base acheteurs qualifiée de + 30 000 personnes, et notre réseau de chasseurs et investisseurs.</p>
        </div>
      </div>
    </div>
  );
}

export default NextSteps;
