import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconConfig, getDateText, getProgressBarClassNames, getCircleClassNames } from '../utils/eventslist';

export const EventsList = ({ events }) => {
  if (events.length === 0) return null;

  const renderIcon = (event) => {
    const iconConfig = getIconConfig(event);
    return iconConfig ? <FontAwesomeIcon {...iconConfig} /> : null;
  };

  const renderDate = (event) => {
    const dateText = getDateText(event);
    return (
      <p className='font-light text-sm text-ih-purple-50'>
        {dateText}
      </p>
    );
  };

  return (
    <div className='flow-root' data-testid='offers-list'>
      <ul role='list' className='sm:items-center sm:flex justify-center'>
        {[...events].map((event, index) => (
          <li
            key={event.id}
            className='relative mb-8 sm:mb-0 flex sm:flex-col sm:justify-center sm:items-center'
            data-testid='timeline-list'
          >
            <div className='flex items-center text-center'>
            <div
                data-testid='circle-event'
                className={getCircleClassNames(event)}
              >
                {renderIcon(event)}
              </div>

              <span className={getProgressBarClassNames(event, index, events.length)} data-testid='progress-bar'></span>
            </div>

            <div className='p-3 ml-4 border-ih-purple-20 border rounded-xl sm:mt-3 sm:px-4 sm:text-center sm:border-0 sm:shadow-ih-0 sm:p-0 sm:ml-0 shadow-ih-1 md:shadow-none h-fit md:h-[40px]'>
              <p className='text-sm font-semibold text-ih-indigo'>
                {event.label}
              </p>

              {renderDate(event)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// PropTypes
EventsList.propTypes = {
  events: PropTypes.array.isRequired,
};
