import React from 'react';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import SponsorshipModule from '../../../../containers/_Pages/SponsorshipModule';

import { D_MessageHeart } from 'hosman-material';
import { FadeIn } from 'hosman-material';

const Sponsorship = () => {
  return (
    <div data-testid='Sponsorship'>
      <PageTitle icon={D_MessageHeart} title='Parrainez un proche' />

      <FadeIn>
        <SponsorshipModule />
      </FadeIn>
    </div>
  )
};

export default Sponsorship;
