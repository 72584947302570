import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-hot-toast';
import { S_Check } from 'hosman-material';
import { longDateTime } from '../../../../../../../../../shared/utils/formattedDates';

import ReasonInput from './components/ReasonInput';
import CancelCommentInput from './components/CancelCommentInput';

import { D_CalendarStar } from 'hosman-material';
import { Card, Box, InfoBox, Button } from 'hosman-material';

import PUBLIC_VISITS from '../../../../../../../../api/ressources/App/visits';

const VisitCancelForm = ({ visit }) => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [cancelComment, setCancelComment] = useState('');
  const [errors, setErrors] = useState({});
  const [isCancelling, setIsCancelling] = useState(false);
  const { userSlug } = useParams();
  let navigate = useNavigate();

  const handleSubmit = async() => {
    if (handleErrors()) return;

    try {
      setIsCancelling(true)
      const response = await PUBLIC_VISITS.cancelVisit(visit.id, userSlug, {
        cancel_reason: selectedReason.value,
        cancel_comment: cancelComment
      })
      navigate('success');
      toast.success(
        <p className='text-ih-indigo font-semibold'>Votre visite a bien été annulée</p>
      , { icon: S_Check, duration: 3000 })
    } catch (error) {
      console.log(error);
    } finally {
      setIsCancelling(false)
    }
  }

  const handleErrors = () => {
    if (!selectedReason) setErrors((prev) => ({ ...prev, cancel_reason: ['*Veuillez sélectionner un motif'] }))
    if (!cancelComment) setErrors((prev) => ({ ...prev, cancel_comment: ['*Veuillez apporter plus de détails'] }))

    return (!selectedReason || !cancelComment)
  }

  return (
    <div className='flex flex-col gap-10 mt-6' data-testid='VisitCancelForm'>
      <p className='h2 text-ih-indigo text-center md:w-2/3 mx-auto'>
        Visite du {longDateTime({ date: visit.start_time })} au {visit.property_address.replace(', France', '')}
      </p>

      <Card classes='flex flex-col items-center mx-auto gap-6 p-6 w-full md:w-[600px]'>
        <Box><FontAwesomeIcon icon={D_CalendarStar} className='text-ih-blue' size='lg' /></Box>

        <p className='h3 text-center text-ih-indigo font semibold md:max-w-[350px]'>Pour quel motif souhaitez-vous annuler la visite ?</p>

        <div className='flex flex-col gap-6 w-4/5'>
          <ReasonInput
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
            errors={errors}
            setErrors={setErrors}
          />

          <CancelCommentInput
            cancelComment={cancelComment}
            setCancelComment={setCancelComment}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <InfoBox avatarSrc={visit.expert.profile_pic_url}>
          <p>Si vous souhaitez décaler votre visite, veuillez d'abord annuler celle-ci, puis en réserver une nouvelle.</p>
        </InfoBox>

        <div className='flex justify-center'>
          <Button label='Annuler la visite' onClick={() => handleSubmit()} isLoading={isCancelling} />
        </div>
      </Card>
    </div>
  )
}

export default VisitCancelForm;
