import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Error from './Error';

const TextInput = ({ refProp , name = '', label = '', type = 'text', value = '', onChange, placeholder = '', paddings = '', disabled = false, errors = {}, icon = null, inputMode = 'text', pattern = '' }) => {
  return (
    <div className='w-full'>
      {label &&
        <div className='font-semibold text-ih-indigo mb-3'>
          <label htmlFor={name}>{label}</label>
        </div>
      }

      <div className='relative'>
        <input
          ref={refProp}
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder || label}
          disabled={disabled}
          className={`${paddings} block w-full h-14 text-ih-indigo rounded-2xl border-ih-blue-80 hover:border-ih-blue p-3 focus:ring-0 focus:border-ih-blue-100 focus:border-2 disabled:cursor-not-allowed disabled:bg-ih-neutral-5 disabled:text-ih-indigo-50 placeholder-ih-indigo-30 shadow-ih-1 ${errors[name] ? 'border-ih-red-60' : ''}`}
          inputMode={inputMode}
          pattern={pattern}
          autoComplete='off'
        />

        {icon &&
          <FontAwesomeIcon icon={icon} className={`text-ih-blue absolute left-3 top-4 mt-0.5 text-lg`} />
        }
      </div>

      <Error name={name} label={label} errors={errors} />
    </div>
  );
};

export default TextInput;

TextInput.propTypes = {
  refProp: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  paddings: PropTypes.string
}
