import React from 'react';
import KeyPointItem from './components/KeyPointItem';
import { KEY_POINTS } from './data';

const KeyPoints = () => {
  return (
    <div className='w-full px-0 md:px-12' data-testid='KeyPoints'>
      <div className='flex flex-col items-center justify-between gap-10 p-7 border border-ih-purple-20 rounded-3xl bg-white'>
        <p className='text-ih-indigo text-xl font-bold text-center'>
          Une expérience simple en <span className='text-ih-blue'>4 étapes clés</span>
        </p>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-6 w-fit mx-auto'>
          {KEY_POINTS.map((point) => (
            <div className='w-56' key={point.id}>
              <KeyPointItem {...point} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyPoints;
