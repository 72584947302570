import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReasonInput from './components/ReasonInput';
import CancelCommentInput from './components/CancelCommentInput';

import { D_CalendarStar } from 'hosman-material';
import { Modal, LoadingLayer, FadeIn, Box, InfoBox, Button } from 'hosman-material';

import { setCancelModalVisitId, cancelVisit } from '../../../../../../../../../../reducers/BuyerApp/visitsSlice';

const CancelModal = ({ expert }) => {
  const { user } = useSelector((state) => state.user);
  const { cancelModalVisitId, visitCancelState } = useSelector((state) => state.buyerVisits);
  const [selectedReason, setSelectedReason] = useState(null);
  const [cancelComment, setCancelComment] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (handleErrors()) return;

    dispatch(cancelVisit({
      visitId: cancelModalVisitId,
      userSlug: user.slug,
      params: {
        cancel_reason: selectedReason.value,
        cancel_comment: cancelComment
      }
    }))
  }

  const handleErrors = () => {
    if (!selectedReason) setErrors((prev) => ({ ...prev, cancel_reason: ['*Veuillez sélectionner un motif'] }))
    if (!cancelComment) setErrors((prev) => ({ ...prev, cancel_comment: ['*Veuillez apporter plus de détails'] }))

    return (!selectedReason || !cancelComment)
  }

  return (
    <Modal isOpen={cancelModalVisitId != null} closeModal={() => dispatch(setCancelModalVisitId(null))}>
      <LoadingLayer isReady={expert != undefined}>
        <FadeIn>
          <div className='flex flex-col items-center mx-auto gap-6 p-6 md:w-[600px]' data-testid='CancelModal'>
            <Box><FontAwesomeIcon icon={D_CalendarStar} className='text-ih-blue' size='lg' /></Box>

            <p className='h3 text-center text-ih-indigo font semibold md:max-w-[350px]'>Pour quel motif souhaitez-vous annuler la visite ?</p>

            <div className='flex flex-col gap-6 w-4/5'>
              <ReasonInput
                selectedReason={selectedReason}
                setSelectedReason={setSelectedReason}
                errors={errors}
                setErrors={setErrors}
              />

              <CancelCommentInput
                cancelComment={cancelComment}
                setCancelComment={setCancelComment}
                errors={errors}
                setErrors={setErrors}
              />
            </div>

            <InfoBox avatarSrc={expert?.photo}>
              <p>Si vous souhaitez décaler votre visite, veuillez d'abord annuler celle-ci, puis en réserver une nouvelle.</p>
            </InfoBox>

            <div className='flex justify-center gap-3'>
              <Button label='Abandonner' type='tertiary' onClick={() => dispatch(setCancelModalVisitId(null))} />
              <Button label='Annuler la visite' onClick={() => handleSubmit()} isLoading={visitCancelState === 'pending'} />
            </div>
          </div>
        </FadeIn>
      </LoadingLayer>
    </Modal>
  )
}

export default CancelModal;
