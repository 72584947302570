import client from '../../client';

const SPONSORSHIPS = {
  getSponsorship() {
    return client.get(`/customers/${client.customerId()}/sponsorships`)
  },

  createSponsorship(params) {
    return client.post(`/customers/${client.customerId()}/sponsorships`, params)
  }
}

export default SPONSORSHIPS;
