import React from 'react';

export const previewContent = {
  planning: {
    title: <>Suivi des visites <span className='text-ih-blue'>en temps réel</span></>,
    checks: ['Visualisez les visites en temps réel', 'Renseignez vos indisponibilités', 'Soyez informé si une visite est annulée'],
    imageSrc: 'https://res.cloudinary.com/vesta-home/image/upload/v1709565088/assets/Napoleon/Images/AppPreviews/planning.webp'
  },
  visitReports: {
    title: <>Accédez à vos <span className='text-ih-blue'>comptes-rendus de visites</span></>,
    checks: ['Obtenez un compte-rendu de visites en temps réel', "Soyez informé de l'intérêt des visiteurs pour votre bien"],
    imageSrc: 'https://res.cloudinary.com/vesta-home/image/upload/v1709565088/assets/Napoleon/Images/AppPreviews/compte-rendus.webp'
  },
  offers: {
    title: <>Visualisez et gérez <span className='text-ih-blue'>vos offres d'achat</span></>,
    checks: ['Accédez au détail de vos offres reçues', 'Acceptez une offre en quelques clics'],
    imageSrc: 'https://res.cloudinary.com/vesta-home/image/upload/v1709566161/assets/Napoleon/Images/AppPreviews/offres.webp'
  },
}
