import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SPONSORSHIP from '../../api/ressources/App/sponsorships';

export const getSponsorships = createAsyncThunk(
  '/sponsorships/getAll',
  async () => {
    const response = await SPONSORSHIP.getSponsorship()
    return response.data
  }
);

export const createSponsorship = createAsyncThunk(
  '/sponsorships/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SPONSORSHIP.createSponsorship(data)
      return response.data
    } catch (err) {
      if (err.response?.status === 422) return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  sponsorships: [],
  godsonForm: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    phone_country_code: 'FR'
  },
  isSubmitting: false,
  error: null,
  formErrors: {}
};

export const sponsorshipsSlice = createSlice({
  name: 'sponsorships',
  initialState,
  reducers: {
    resetSponsorships(state) {
      state.sponsorships = initialState.sponsorships;
    },
    resetErrorAndForm(state) {
      state.error = initialState.error;
      state.godsonForm = initialState.godsonForm;
    },
    updateGodsonForm(state, { payload }) {
      state.godsonForm = payload;
    },
    updateFormErrors(state, { payload }) {
      if (Object.keys(payload).length > 0) state.formErrors = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSponsorships.fulfilled, (state, { payload }) => {
      state.sponsorships = payload;
    })
    builder.addCase(createSponsorship.pending, (state) => {
      state.isSubmitting = true;
    })
    builder.addCase(createSponsorship.fulfilled, (state, { payload }) => {
      state.sponsorships = [...state.sponsorships, payload];
      state.isSubmitting = false;
      state.godsonForm = initialState.godsonForm;
      toast.success('Votre parainage a bien été créé');
    })
    builder.addCase(createSponsorship.rejected, (state, { payload }) => {
      if (payload.error.message.error_type === 'validation_error') state.formErrors = payload.error.message.errors;
      else state.error = payload.error;

      state.isSubmitting = false;
    })
  },
})

export const { resetSponsorships, updateGodsonForm, resetErrorAndForm, updateFormErrors } = sponsorshipsSlice.actions;

export default sponsorshipsSlice.reducer;
