import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { generateLink } from '../../../../shared/utils/railsRoutes';
import CustomRoutes from '../../../../shared/utils/customRoutes';
import AuthenticatedRoutes from '../../../utils/AuthenticatedRoutes';
import ErrorsBoundary from '../../../../shared/utils/errorsBoundary';

import BuyerApp from '../BuyerApp';
import SellerApp from '../SellerApp';
import AcceptOffer from '../../_Pages/AcceptOffer';
import MyAccount from '../../_Pages/MyAccount';
import BuyerAlertCreator from '../../_Pages/Public/BuyerAlertCreator';
import EstimationFunnel from '../../_Pages/Public/EstimationFunnel';
import EstimationResult from '../../_Pages/Public/EstimationResult';
import VisitCanceler from '../../_Pages/Public/VisitCanceler';

import { ClientSwitch, AccountCard } from 'hosman-material';

import { getUser, updateAuthState } from '../../../reducers/App/userSlice';

const App = () => {
  const { user } = useSelector((state) => state.user);
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const clientSwitchOptions = [
    { section: 'seller', title: 'Espace vendeur', path: '/ev' },
    { section: 'buyer', title: 'Espace acheteur', path: '/ea' }
  ]
  const matchedPath = clientSwitchOptions.find((option) => location.pathname.includes(option.path));
  const [selectedOption, setSelectedOption] = useState(matchedPath || { path: '/' })

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      dispatch(updateAuthState('none'));
      return;
    }

    if (localStorage.getItem('customerId') === 'none') return;
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (user.id && localStorage.sessionId !== localStorage.ga4SessionId) {
      localStorage.setItem('ga4SessionId', localStorage.sessionId)
    }
    localStorage.setItem('userId', user?.id)
  }, [user]);

  useEffect(() => {
    if (!user.first_name || selectedOption.section) return;

    if (user.salesCount > 0) {
      setSelectedOption(clientSwitchOptions[0])
      return
    }

    if (user.salesInterestsCount > 0) {
      setSelectedOption(clientSwitchOptions[1])
      return
    }
  }, [user]);

  const switchApp = (option) => {
    if (!location.pathname.includes(option.path)) {
      setSelectedOption(option);
      navigate(option.path);
    }
  }

  const clientSwitch = () => {
    if (!user || user.salesCount == 0 || user.salesInterestsCount == 0) return;

    return (
      <div className='mt-4'>
        <ClientSwitch
          options={clientSwitchOptions}
          selectedOption={selectedOption}
          defaultOption={selectedOption}
          handleSelect={switchApp}
        />
      </div>
    )
  }

  const accountCard = () => {
    return (
      <AccountCard
        userName={`${user.first_name} ${user.last_name}`}
        userSpace={selectedOption.title}
        accountLink={() => navigate(`/mon-compte`)}
        logoutLink={generateLink(RailsRoutes.destroy_user_session_path())}
        eugeneLink={generateLink(RailsRoutes.root_path())}
      />
    )
  }

  return (
    <ErrorsBoundary channel='NAPOLEON_MONITORING_CHANNEL'>
      <div data-testid='app'>
        <CustomRoutes>
          <Route path='/alerte-achat/*' element={<BuyerAlertCreator />} />
          <Route path='/estimation/resultat/:uniqueHash/*' element={<EstimationResult />} />
          <Route path='/estimation/*' element={<EstimationFunnel />} />
          <Route path='/annulation-visite/:visitId/:userSlug/*' element={<VisitCanceler />} />

          <Route element={<AuthenticatedRoutes user={user} />}>
            <Route index element={<Navigate to={selectedOption.path} />} />
            <Route path='/ea/*' element={<BuyerApp accountCard={accountCard} clientSwitch={clientSwitch} />} />
            <Route path='/ev/*' element={<SellerApp accountCard={accountCard} clientSwitch={clientSwitch} />} />
            <Route path='/traitement-offre/:offerId/*' element={<AcceptOffer />} />
            <Route path='/mon-compte' element={<MyAccount accountCard={accountCard} />} />
          </Route>
        </CustomRoutes>
      </div>
    </ErrorsBoundary>
  )
}

export default App;
