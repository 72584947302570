import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import CustomRoutes from '../../../../../shared/utils/customRoutes';
import { generateLink } from '../../../../../shared/utils/railsRoutes';

import VisitCancelModule from './pages/VisitCancelModule';
import VisitCancelSuccess from './pages/VisitCancelSuccess';

import { HosmanLogo, Avatar } from 'hosman-material';

const VisitCanceler = () => {
  const [visit, setVisit] = useState({})

  return (
    <>
      <div className='sticky top-0 border-b-2 border-ih-purple-30 bg-white z-30'>
        <div className='flex items-center justify-between h-24 px-12'>
          <div className='w-full flex justify-between items-center'>
            <a href={generateLink(RailsRoutes.root_path())} className='w-max mx-auto md:mx-0'>
              <HosmanLogo width={160} />
            </a>

            {visit.expert &&
              <div className='hidden md:flex items-center gap-3'>
                <Avatar imageSrc={visit.expert.profile_pic_url} userName={`${visit.expert.first_name} ${visit.expert.last_name}`} />

                <div className='mr-4'>
                  <p className='text-sm'>Expert Hosman</p>

                  <p className='text-sm flex flex-wrap gap-1'>
                    <span className='font-semibold'>{visit.expert.first_name}</span>
                    <span>({visit.expert.phone_number.replace(/.{2}/g, '$& ').trim()})</span>
                  </p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <CustomRoutes>
        <Route index element={<VisitCancelModule visit={visit} setVisit={setVisit} />} />
        <Route path='/success' element={<VisitCancelSuccess visit={visit} />} />
      </CustomRoutes>
    </>
  )
}

export default VisitCanceler;
