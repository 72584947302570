export const KEY_POINTS = [
  {
    id: 1,
    title: 'Inscription du filleul',
    description: 'Partagez-nous les coordonnées de votre filleul.'
  },
  {
    id: 2,
    title: 'Accompagnement Hosman',
    description: "Nous l'accompagnerons dans son projet de vente."
  },
  {
    id: 3,
    title: 'Signature du mandat',
    description: "S'il décide de confier son bien à Hosman, vous recevrez 200€."
  },
  {
    id: 4,
    title: 'Promesse de vente',
    description: 'Si la vente de son bien se concrétise, nous vous verserons alors 400€.'
  }
];
