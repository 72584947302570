import client from '../../client';

const VISITS = {
  authCancelVisit(visitId, params) {
    return client.post('/visits/verify_infos', { visit_id: visitId, user: params })
  },

  getVisit(visitId, userSlug) {
    return client.get(`/visits/${visitId}`, { user_slug: userSlug });
  },

  cancelVisit(visitId, userSlug, params) {
    return client.put(`/visits/${visitId}`, { user_slug: userSlug, visit: params })
  },
}

export default VISITS;
