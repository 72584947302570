import React from 'react';

import { EventsList } from '../../EventsList';

import { Label } from 'hosman-material';

import { getSponsorshipStatus, getEventsForSponsorship } from '../../../utils/sponsorship';

export const GodsonCard = ({ sponsorship, index }) => {
  return (
    <div className='flex flex-col items-center justify-center gap-7 bg-white rounded-3xl border border-ih-purple-20'>
      <div className='flex flex-col items-center justify-center gap-5 p-7'>
        <div className='flex flex-col md:flex-row items-center justify-center gap-2'>
          <p className='text-ih-indigo text-md font-medium'>
            Filleul {index + 1} : {sponsorship.first_name} {sponsorship.last_name}
          </p>

          <Label color={getSponsorshipStatus(sponsorship).color} textColor='text-white'>
            {getSponsorshipStatus(sponsorship).label}
          </Label>
        </div>

        <EventsList events={getEventsForSponsorship(sponsorship)} />
      </div>
    </div>
  )
};
