import React from 'react';

import { TextAreaInput } from 'hosman-material';

const CancelCommentInput = ({ cancelComment, setCancelComment, errors, setErrors }) => {
  const handleChange = (e) => {
    setCancelComment(e.target.value);
    setErrors((prev) => ({ ...prev, cancel_comment: null }))
  }

  return (
    <TextAreaInput
      rows={4}
      label='Veuillez apporter plus de détails :'
      name='cancel_comment'
      value={cancelComment}
      onChange={(e) => handleChange(e)}
      errors={errors}
    />
  )
}

export default CancelCommentInput;
