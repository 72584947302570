import React from 'react';
import { useSelector } from 'react-redux';
import { goodStateOptions, constructionTimeOptions } from './selectOptions';

import { NumberInput, NumberInputStepper, SelectInput, InfoIcon } from 'hosman-material';

const HouseInputs = ({ handleInputChange }) => {
  const { estimationData, estimationData: { mainInfos }, errors} = useSelector((state) => state.estimationFunnel);

  return (
    <div className='flex flex-col gap-6' data-testid='HouseInputs'>
      <NumberInput
        name='living_area'
        placeholder='0'
        label='Surface habitable'
        onChange={(e) => handleInputChange(e, { integer: true })}
        value={mainInfos.living_area}
        sign='m²'
        allowDecimal
        errors={errors}
      />

      <div className='flex flex-col gap-2'>
        <NumberInput
          name='land_area'
          placeholder='0'
          label='Surface du terrain'
          onChange={(e) => handleInputChange(e, { integer: true })}
          value={mainInfos.land_area}
          sign='m²'
          allowDecimal
          errors={errors}
        />
        <div className='flex items-center gap-2'>
          <InfoIcon bgColor='bg-ih-indigo-30' iconColor='text-ih-indigo-60' />
          <p className='text-ih-indigo-40 text-sm'>Surface cumulée de la maison et du jardin</p>
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <NumberInputStepper
        placeholder='0'
          name='total_floor'
          label="Nombre de niveaux"
          onChange={handleInputChange}
          value={mainInfos.total_floor}
          errors={errors}
        />
        <div className='flex items-center gap-2'>
          <InfoIcon bgColor='bg-ih-indigo-30' iconColor='text-ih-indigo-60' />
          <p className='text-ih-indigo-40 text-sm'>Mettre 0 si maison de plain-pied. Ne pas compter le sous-sol ou la cave comme un niveau</p>
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <NumberInputStepper
          name='room_number'
          placeholder='0'
          label='Nombre de pièces'
          onChange={handleInputChange}
          value={mainInfos.room_number}
          errors={errors}
        />
        <div className='flex items-center gap-3'>
          <InfoIcon bgColor='bg-ih-indigo-30' iconColor='text-ih-indigo-60' />
          <p className='text-ih-indigo-40 text-sm'>Ne comptez que les chambres, bureaux, ou salons de plus de 9m²</p>
        </div>
      </div>

      <NumberInputStepper
        name='bedroom_number'
        placeholder='0'
        label='Nombre de chambres'
        onChange={handleInputChange}
        value={mainInfos.bedroom_number}
        errors={errors}
      />

      <div className='flex flex-col gap-3'>
        <SelectInput name='construction_time' label='Période de construction'
          options={constructionTimeOptions}
          selectedOption={constructionTimeOptions.find(option => option.value === mainInfos.construction_time)}
          handleChange={(option) => handleInputChange({ target: { name: 'construction_time', value: option.value }})}
          errors={errors}
        />
      </div>

      <div className='flex flex-col gap-3'>
        <SelectInput name='good_state' label='État général du bien'
          options={goodStateOptions}
          selectedOption={goodStateOptions.find(option => option.value === mainInfos.good_state)}
          handleChange={(option) => handleInputChange({ target: { name: 'good_state', value: option.value }})}
          errors={errors}
        />
      </div>
    </div>
  )
}

export default HouseInputs;
