import React, { useEffect, useState } from 'react';

import EstimatedPrices from './components/EstimatedPrices';
import PotentialBuyers from './components/PotentialBuyers';
import ExpertMeeting from './components/ExpertMeeting';
import NextSteps from './components/NextSteps';
import Sponsorship from './components/Sponsorship';
import PropertyAdverts from './components/PropertyAdverts';
import SoldProperties from './components/SoldProperties';
import HosmanPros from './components/HosmanPros';

import { FadeIn, Separator } from 'hosman-material';
import VALUATIONS from '../../../api/ressources/SellerApp/valuations';
import { greeting } from "../../../../shared/utils/helpers/hosmanVars";

const EstimationResult = ({ sale }) => {
  const [valuation, setValuation] = useState(sale.last_yanport_valuation)
  const saleIsDead = sale.state === 'dead'

  useEffect(() => {
    if (valuation || !sale.last_yanport_valuation_id) return;

    const getValuation = async (valuationId) => {
      const response = await VALUATIONS.getValuation(valuationId)
      setValuation(response.data)
    }

    getValuation(sale.last_yanport_valuation_id)
  }, [sale]);

  return (
    <div data-testid='EstimationResult' className='mb-28'>
      <FadeIn>
        <div className='flex flex-col justify-center items-center gap-5 md:gap-1 mb-10'>
          <div className='h2 text-center'>
            {sale.owner ? `${greeting(sale.owner)}, v` : 'V'}oici votre <span className='text-ih-blue'> première estimation !</span>
          </div>

          <div className='text-ih-indigo-60'>{sale.property.street_number} {sale.property.street_name}, {sale.property.zip_code} {sale.property.city}</div>
        </div>

        <div className='flex flex-col justify-center items-center md:px-10 md:flex-row gap-6 md:gap-10'>
          <div className='flex flex-col justify-center gap-8 md:gap-3 w-full'>
            <EstimatedPrices sale={sale} valuation={valuation} setValuation={setValuation} />
            {(!saleIsDead && valuation) && <PotentialBuyers sale={sale} valuation={valuation} />}
          </div>

          {!saleIsDead &&
            <div className='md:w-2/3'>
              <ExpertMeeting sale={sale} />
            </div>
          }
        </div>

        {!saleIsDead &&
          <div className='mt-16'>
            <NextSteps />
            <Separator margins='mt-16' />
          </div>
        }

        <div className='flex flex-col justify-center gap-12'>
          <SoldProperties sale={sale} />

          <HosmanPros sale={sale} />

          <Separator />

          <div className='flex flex-col md:flex-row items-center justify-center gap-8'>
            <Sponsorship sale={sale} />
            <PropertyAdverts sale={sale} />
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

export default EstimationResult;
