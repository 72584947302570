import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CreationForm from './components/CreationForm';
import Dashboard from './components/Dashboard';
import GodsonsList from './components/GodsonsList';
import KeyPoints from './components/KeyPoints';

import { getSponsorships, resetSponsorships } from '../../../reducers/_Pages/sponsorshipModuleSlice';

const SponsorshipModule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSponsorships());

    return function cleanup() {
      dispatch(resetSponsorships())
    };
  }, []);

  return (
    <div className='flex flex-col items-center justify-center gap-12' data-testid='SponsorshipModule'>
      <div className='flex flex-col items-center justify-center gap-2 w-full md:w-2/3'>
        <p className='text-ih-indigo h1 text-center'>
          Recommandez Hosman à un proche : <span className='text-ih-blue'>gagnez jusqu'à 600€</span>
        </p>

        <p className='w-full text-ih-indigo-50 text-md font-medium text-center'>
          Voici les bénéfices que vous recevrez pour chaque parrainage effectué
        </p>
      </div>

      <div className='flex flex-col md:flex-row items-center gap-6'>
        <CreationForm />

        <Dashboard />
      </div>

      <GodsonsList />

      <KeyPoints />
    </div>
  )
};

export default SponsorshipModule;
