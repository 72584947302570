import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import CustomRoutes from '../../../../../../../shared/utils/customRoutes';
import { houseWithGarden } from '../../../../../../../shared/assets/emojis';

import PageTitle from '../../../../../../components/PageTitle/PageTitle';
import Property from './views/Property';
import Visits from './views/Visits';
import Offers from './views/Offers';
import Documents from './views/Documents';
import Contacts from './views/Contacts';
import { LoadingLayer, FadeIn, Tabs, Tab } from 'hosman-material';

import { Emoji } from 'hosman-material';
import { D_CalendarStar } from 'hosman-material';

const InterestShow = () => {
  let { saleId } = useParams();
  const { salesInterests, saleInterestsState } = useSelector((state) => state.buyerSalesInterests);
  const { allVisits, visitsState } = useSelector((state) => state.buyerVisits);
  const { allOffers, offersState } = useSelector((state) => state.buyerOffers);
  const [sale, setSale] = useState({})
  const [visits, setVisits] = useState([])
  const [offers, setOffers] = useState([])

  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  let location    = useLocation();
  let navigate    = useNavigate();
  const documents = []

  useEffect(() => {
    if (saleInterestsState === 'done') {
      const currentSale = salesInterests.find(sale => sale.id == saleId)
      if (currentSale) setSale(salesInterests.find(sale => sale.id == saleId));
      if (!currentSale) navigate('/ea');
    }
    if (visitsState === 'done') setVisits(allVisits.filter(visit => visit.sale_id == saleId));
    if (offersState === 'done') setOffers(allOffers.filter(offer => offer.sale_id == saleId));
  }, [salesInterests, allVisits, allOffers]);

  useEffect(() => {
    // Catch browser back button to redirect to BuyerApp root
    const handleBackButton = () => {
      navigate('/ea');
    };

    window.addEventListener('popstate', handleBackButton);
  }, []);

  useEffect(() => {
    let baseItems = [{ name: 'Le bien', path: 'le-bien' }]

    if (visits.length > 0) baseItems.splice(1, 0, { name: visits.length > 1 ? 'Mes visites' : 'Ma visite', path: 'mes-visites', count: visits.length })
    if (offers.length > 0) baseItems.splice(2, 0, { name: offers.length > 1 ? 'Mes offres' : 'Mon offre', path: 'mes-offres', count: offers.length })

    // Adds nextSteps, document && contact tabs when offer is accepted
    if (offers.map(offer => offer.review_status).includes('accepted')) {
      baseItems.splice(3, 0, { name: 'Documents', path: 'documents', count: documents.length || null })
    }

    baseItems.push({ name: 'Contacts', path: 'contacts' })

    setItems(baseItems)
  }, [visits, offers]);

  useEffect(() => {
    if (items.length > 0) {
      const matchedItem = items.find((item) => location.pathname.includes(item.path));
      setSelectedItem(matchedItem || items[0])
    }
  }, [items]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    navigate(item.path);
  }

  return (
    <div data-testid='InterestShow'>
      <PageTitle icon={D_CalendarStar} title='Visites et offres' />

      <LoadingLayer isReady={[saleInterestsState, visitsState, offersState].every(state => state === 'done')}>
        <FadeIn>
          <div className='flex justify-center md:justify-start mb-4'>
            <div className='h2 flex flex-col md:flex-row-reverse items-center gap-3'>
              <Emoji emojiSrc={houseWithGarden} />
              <p className='text-ih-indigo'>{sale.property?.address}</p>
            </div>
          </div>

          <div className='mb-5'>
            <Tabs>
              {items.map((item) => {
                return (
                  <Tab
                    key={item.name}
                    item={item}
                    selectedItem={selectedItem}
                    handleSelect={handleSelect}
                  />
                );
              })}
            </Tabs>
          </div>

          <CustomRoutes>
            <Route index element={<Navigate to='le-bien' />} />
            <Route path='/le-bien' element={<Property sale={sale} offers={offers} visits={visits} />} />
            <Route path='/mes-visites' element={<Visits visits={visits} sale={sale} />} />
            <Route path='/mes-offres' element={<Offers offers={offers} sale={sale} />} />
            <Route path='/documents' element={<Documents />} />
            <Route path='/contacts' element={<Contacts offers={offers} visits={visits} sale={sale} />} />
          </CustomRoutes>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default InterestShow;
