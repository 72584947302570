export const SPONSORSHIP_STATUS = {
  FINALIZED: {
    label: 'Parrainage finalisé',
    color: 'bg-ih-green',
  },
  IN_PROGRESS: {
    label: 'Parrainage en cours',
    color: 'bg-ih-blue',
  },
  NOT_FINALIZED: {
    label: 'Parrainage non finalisé',
    color: 'bg-ih-red',
  },
};

export const EVENTS = [
  { id: 1, label: 'Inscription du filleul' },
  { id: 2, label: 'Signature du mandat', warningLabel: 'Mandat non signé' },
  { id: 3, label: 'Promesse de vente', warningLabel: 'Mandat annulé' },
];

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
};

export const getSponsorshipStatus = (sponsorship) => {
  const { sale_state, state_changes: { last_awaiting_notarial_deed_at } } = sponsorship;

  if (sale_state === 'dead') return SPONSORSHIP_STATUS.NOT_FINALIZED;

  if (last_awaiting_notarial_deed_at) return SPONSORSHIP_STATUS.FINALIZED;

  return SPONSORSHIP_STATUS.IN_PROGRESS;
};

export const getEventsForSponsorship = (sponsorship) => {
  const { created_at, state_changes: { last_awaiting_publication_at, last_awaiting_notarial_deed_at }, sale_state } = sponsorship;
  const isDead = sale_state === 'dead';

  return EVENTS.map(({ id, label, warningLabel }) => {
    let finalLabel = label;
    if ((id === 2 && !last_awaiting_publication_at) || (id === 3 && isDead)) finalLabel = warningLabel;

    if (id === 1) {
      return {
        id,
        label: finalLabel,
        date: formatDate(created_at),
        done: true,
        cancelled: false
      };
    }

    return {
      id,
      label: finalLabel,
      date: formatDate(id === 2 ? last_awaiting_publication_at : last_awaiting_notarial_deed_at),
      done: id === 2 ? !!last_awaiting_publication_at : !!last_awaiting_notarial_deed_at,
      cancelled: isDead && (id === 2 ? !last_awaiting_publication_at : !last_awaiting_notarial_deed_at)
    };
  });
};
