import { S_Check, S_Xmark } from 'hosman-material';
import classNames from 'classnames';

export const getIconConfig = (event) => {
  if (event.id === 1 || event.done) return { icon: S_Check, className: 'h-4 w-4 text-white' };
  if (event.cancelled) return { icon: S_Xmark, className: 'h-4 w-4 text-white' };

  return null;
};

export const getDateText = (event) => {
  if (!event.date) return event.customNoDate || '';

  return event.date;
};

export const getProgressBarClassNames = (event, index, totalEvents) => {
  return classNames(
    'progress-bar sm:flex absolute z-10',
    {
      'w-0.5 h-full min-h-[100px] top-[-60px] sm:w-full sm:min-h-0 sm:top-3 sm:h-0.5': index > 0,
      'sm:left-[-4rem]': index !== (totalEvents - 1) && event.label.length < 15,
      'sm:left-[-5rem]': index === (totalEvents - 1) || event.label.length >= 15,
      'left-[12px]': event.label.length < 18,
      'left-[14px]': event.label.length >= 18,
      'bg-ih-green': event.done && !event.cancelled,
      'bg-ih-red': event.cancelled,
      'bg-ih-purple-20': !event.done && !event.cancelled && !event.date
    }
  );
};

export const getCircleClassNames = (event) => {
  return classNames(
    'h-6 w-6 rounded-full z-20 flex justify-center items-center',
    {
      'bg-ih-green': event.done && !event.cancelled,
      'bg-ih-red': event.cancelled,
      'bg-ih-purple-20': !event.done && !event.cancelled && !event.date
    }
  );
};
