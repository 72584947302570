import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck } from 'hosman-material';

const Dashboard = () => {
  const { sponsorships } = useSelector((state) => state.sponsorshipModule);

  return (
    <div className='w-full md:w-1/2 flex flex-col items-center justify-between gap-5 p-7 border border-ih-purple-20 rounded-3xl bg-white' data-testid='Dashboard'>
      <p className='text-ih-indigo h4 text-center'>
        Plus vous parrainez, plus vous gagnez !
      </p>

      <div className='flex flex-col items-center justify-between gap-5 p-5 border border-ih-purple-20 rounded-3xl'>
        <div className='flex flex-col items-center justify-center'>
          <p className='text-ih-indigo font-medium text-sm'>
            Vos parrainages
          </p>

          <p className='h2 text-ih-blue'>
            {sponsorships.length === 0 ? '0' : `${sponsorships.length.toString().padStart(2, '0')}`}
          </p>
        </div>
      </div>

      <div className='flex flex-col md:flex-row md:flex-wrap gap-3 justify-center'>
        <div className='flex flex-col items-center gap-3 border border-ih-green-40 rounded-2xl bg-ih-green-5 p-3'>
          <p className='font-medium'>Signature mandat</p>

          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green text-2xl' />
            <p className='text-xl font-semibold'>200€ offerts</p>
          </div>
        </div>

        <div className='flex flex-col items-center gap-3 border border-ih-green-40 rounded-2xl bg-ih-green-5 p-3'>
          <p className='font-medium'>Promesse de vente</p>

          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green text-2xl' />
            <p className='text-xl font-semibold'>400€ offerts</p>
          </div>
        </div>
      </div>

      <a href='https://share.hosman.co/cgu/' className='text-center underline font-semibold text-sm' target='_blank'>
        Termes et conditions de parrainage
      </a>
    </div>
  )
};

export default Dashboard;
