import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Inputs from './components/Inputs';

import { Button } from 'hosman-material';

import { createSponsorship } from '../../../../../reducers/_Pages/sponsorshipModuleSlice';

const CreationForm = () => {
  const { godsonForm, isSubmitting } = useSelector((state) => state.sponsorshipModule);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await dispatch(createSponsorship({ sponsorship: godsonForm }));
  };

  return (
    <div className='w-full md:w-[455px]' data-testid='CreationForm'>
      <form onSubmit={handleSubmit} className='flex flex-col items-center justify-between gap-5 p-7 border-2 border-ih-blue rounded-3xl bg-ih-purple-5'>
        <div className='flex flex-col items-center justify-center gap-2'>
          <p className='text-ih-indigo text-xl font-bold text-center'>
            Coordonnées de votre filleul
          </p>

          <p className='w-full text-ih-indigo-50 text-md font-medium text-center'>
            Renseignez ici les coordonnées du futur vendeur que vous souhaitez parrainer avec Hosman
          </p>
        </div>

        <Inputs />

        <div className='flex justify-center'>
          <Button
            label='Enregistrer mon parrainage'
            isLoading={isSubmitting}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default CreationForm;
