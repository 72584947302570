import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { validateStep } from '../Validators';

import { FadeIn } from 'hosman-material';
import { S_CircleCheck } from 'hosman-material';
import NavigationButtons from '../../components/NavigationButtons'
import AddressInput from './components/AddressInput';
import TextInput from './components/TextInput';
import ReinsuranceBlocks from './components/ReinsuranceBlocks';
import { updateStep, updateEstimationData, updateErrors, updateDisplayStreetNumberError } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';
import { fetchAddressData, extractAddress } from './service/googleMapsService';

const Address = () => {
  const { estimationData, estimationData: { placeData: { formatted_address }}, errors, displayStreetNumberError } = useSelector((state) => state.estimationFunnel);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [streetNumber, setStreetNumber] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('formatted_address')) return;

    const newEstimationData = {
      ...estimationData,
      placeData: {
        formatted_address: searchParams.get('formatted_address') || null,
        street_number:     searchParams.get('street_number') || null,
        street_name:       searchParams.get('street_name') || null,
        zip_code:          searchParams.get('zip_code') || null,
        city:              searchParams.get('city') || null,
        country_code:      searchParams.get('country_code') || null,
        lat:               searchParams.get('lat') || null,
        lng:               searchParams.get('lng') || null
      }
    }

    dispatch(updateEstimationData(newEstimationData))

    const contract = validateStep({ stepNumber: 1, data: newEstimationData });
    navigate(location.pathname, { replace: true });

    if (contract.valid) {
      dispatch(updateStep({ stepNumber: 2 }))
      return
    }

    setTriggerValidation(true)
  }, []);

  useEffect(() => {
    if (!triggerValidation) return

    const contract = validateStep({ stepNumber: 1, data: estimationData });
    if (contract.valid) {
      dispatch(updateStep({ stepNumber: 2 }))
    } else {
      toast.error('Le numéro de rue est requis', { id: 'address-validation-fail', duration: 3000 })
      dispatch(updateErrors(contract.errors))
      dispatch(updateDisplayStreetNumberError(true))
    }
  }, [triggerValidation]);

  const handleChange = (addressObj) => {
    dispatch(updateErrors({}))
    setTriggerValidation(false)
    dispatch(updateEstimationData({ ...estimationData, placeData: addressObj }))

    if (addressObj.lat) {
      setTriggerValidation(true)
    } else {
      dispatch(updateDisplayStreetNumberError(false))
    }
  }

  const handleChangeStreetNumber = async (e) => {
    try {
      dispatch(updateErrors({}))
      setStreetNumber(e.target.value)
      setTriggerValidation(false)

      const searchTerm = estimationData.placeData.street_name
        ? `${e.target.value} ${estimationData.placeData.street_name}`
        : `${e.target.value} ${estimationData.placeData.formatted_address}`;

      const result = await fetchAddressData(searchTerm);

      const addressObj = extractAddress('formatted_address', result)

      if (addressObj == null) return;
      dispatch(updateEstimationData({ ...estimationData, placeData: addressObj }))
    } catch (error) {
      toast.error('Une erreur est survenue lors de la récupération de l\'adresse');
    }
  }

  return (
    <div data-testid='Address'>
      <FadeIn>
        <div className='flex flex-col justify-center items-center pt-14'>
          <div className='flex flex-col items-center justify-center gap-7 w-full md:w-3/5 xl:w-2/5'>
            <div className='flex flex-col gap-7'>
              <div className='h2 text-ih-indigo text-left'>
                <p className='w-full md:w-3/4'>Obtenez votre estimation en moins de 2 minutes :</p>
              </div>

              <div className='flex flex-col gap-4 w-full bg-hosman-gradient-2 border border-ih-blue rounded-3xl p-5'>
                <AddressInput
                  name='formatted_address'
                  label="Quelle est l'adresse du bien à estimer ?"
                  onChange={handleChange}
                  value={formatted_address}
                  placeholder="Saisir l'adresse du bien ici..."
                  listPlacement='bottom-auto relative'
                  errors={errors}
                />

                <p className='text-ih-indigo-60 text-sm'>Ex : 12 rue Saint Denis, 75011 Paris</p>

                <div className={`transition-all duration-300 ease-in-out ${
                  displayStreetNumberError ? 'opacity-100 h-fit' : 'opacity-0 max-h-0'
                } overflow-hidden`}
                >
                  <TextInput
                    name='street_number'
                    label='Numéro de rue'
                    onChange={handleChangeStreetNumber}
                    value={streetNumber || ''}
                    placeholder='12'
                    listPlacement='bottom-auto relative'
                    errors={errors}
                    inputMode='numeric'
                    pattern='[0-9]*'
                  />
                </div>
              </div>

              <div className='flex items-center justify-center flex-wrap gap-4'>
                <div className='flex items-center gap-2 text-sm'>
                  <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue text-lg' />
                  <p>Rapide</p>
                </div>

                <div className='flex items-center gap-2 text-sm'>
                  <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue text-lg' />
                  <p>Sans engagement</p>
                </div>

                <div className='flex items-center gap-2 text-sm'>
                  <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue text-lg' />
                  <p>Données fiables et vérifiées</p>
                </div>
              </div>
            </div>

            <NavigationButtons classes='block mb-14' />
          </div>

          <ReinsuranceBlocks />
        </div>
      </FadeIn>
    </div>
  )
}

export default Address;
