import React from 'react';

import SponsorshipModule from '../../../../../containers/_Pages/SponsorshipModule';

import { FadeIn } from 'hosman-material';

const Sponsorship = () => {
  return (
    <div data-testid='Sponsorship'>
      <FadeIn>
        <SponsorshipModule />
      </FadeIn>
    </div>
  )
};

export default Sponsorship;
