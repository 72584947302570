import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { Card, TextInput, Button } from 'hosman-material';
import { S_Check } from 'hosman-material';

import VISITS from '../../../../../../../api/ressources/App/visits';

const Authentication = ({ setVisit }) => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [isPending, setIsPending] = useState(false)
  const { visitId, userSlug } = useParams();
  let navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrors({});
  }

  const handleAuth = async() => {
    try {
      setIsPending(true)
      const response = await VISITS.authCancelVisit(visitId, { email: email })
      if (response.status == 200) fetchVisit();
    } catch (error) {
      setIsPending(false)
      setErrors({ email: ["*L'email ne correspond pas"] })
    }
  }

  const fetchVisit = async() => {
    try {
      const response = await VISITS.getVisit(visitId, userSlug)
      setVisit(response.data);

      if (response.data.canceled) {
        toast.success(
          <p className='text-ih-indigo font-semibold'>Cette visite est déja annulée</p>
        , { icon: S_Check, duration: 3000 })
        navigate('success');
      }
    } catch (error) {
      setErrors({ email: ["*L'email ne correspond pas"] })
    } finally {
      setIsPending(false)
    }
  }

  return (
    <div className='flex flex-col gap-10 mt-20' data-testid='Authentication'>
      <p className='h2 text-ih-indigo text-center'>Authentification</p>

      <Card classes='flex flex-col items-center gap-6 p-6 w-full md:w-[600px] mx-auto'>
        <TextInput
          label='Adresse mail'
          name='email'
          value={email}
          onChange={(e) => handleEmail(e)}
          errors={errors}
        />

        <Button label="S'authentifier" onClick={handleAuth} disabled={!!!email} isLoading={isPending} />
      </Card>
    </div>
  )
}

export default Authentication;
