import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { greeting } from '../../../../../../shared/utils/helpers/hosmanVars';

import { GodsonCard } from './components/GodsonCard';
import { ModalError } from './components/ModalError';

const GodsonsList = () => {
  const { user } = useSelector((state) => state.user);
  const { sponsorships } = useSelector((state) => state.sponsorshipModule);
  const lastCardRef = useRef(null);
  const prevLength = useRef(0);

  useEffect(() => {
    if (!lastCardRef.current) return;

    if (sponsorships.length > prevLength.current) {
      if (prevLength.current > 0) {
        lastCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      prevLength.current = sponsorships.length;
    }
  }, [sponsorships.length]);

  return (
    <div className='bg-ih-purple-5 py-0 md:py-14' data-testid='GodsonsList'>
      <div className='flex flex-col items-center justify-center gap-6'>
        <div className='md:w-1/2 text-center'>
          <p className='text-ih-indigo h2'>
            Suivi de <span className='text-ih-blue'>vos parrainages</span>
          </p>

          <p className='text-ih-indigo-50 font-medium'>{greeting(user)}, merci de nous recommander à votre entourage ! Hosman s'est construit sur la recommandation client et c'est grâce à vous</p>
        </div>

        {sponsorships.map((sponsorship, index) => (
          <div
            key={sponsorship.id}
            ref={index === sponsorships.length - 1 ? lastCardRef : null}
          >
            <GodsonCard sponsorship={sponsorship} index={index} />
          </div>
        ))}
      </div>

      <ModalError />
    </div>
  )
};

export default GodsonsList;
