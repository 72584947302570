import React from 'react';

const KeyPointItem = ({ id, title, description }) => (
  <div className='flex flex-col items-center gap-4'>
    <div className='bg-ih-blue text-white h3 flex items-center justify-center w-10 h-10 rounded-xl'>
      {id.toString().padStart(2, '0')}
    </div>

    <div className='flex flex-col gap-1 items-center'>
      <p className='text-ih-blue font-semibold text-sm'>
        {title}
      </p>

      <p className='text-ih-indigo text-sm text-center'>
        {description}
      </p>
    </div>
  </div>
);

export default KeyPointItem;
