import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { longDateTime } from '../../../../../../../shared/utils/formattedDates';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';

import { Button } from 'hosman-material';
import { S_Check } from 'hosman-material';

const VisitCancelSuccess = ({ visit }) => {
  if (!visit.id) {
    location.href = location.href.replace('/success', '');
    return
  }

  return (
    <div className='bg-hosman-gradient-1 h-[calc(100vh-98px)] flex items-center justify-center' data-testid='VisitCancelSuccess'>
      <div className='flex flex-col items-center gap-6 text-ih-indigo text-center'>
        <FontAwesomeIcon icon={S_Check} className='flex items-center justify-center p-3 rounded-full bg-ih-green text-white' />

        <p className='h2 md:w-1/2 px-6'>
          Votre visite du {longDateTime({ date: visit.start_time })} au {visit.property_address.replace(', France', '')} a bien été annulée
        </p>

        <p>Vous souhaitez visiter ce bien sur un autre créneau ? </p>

        <a href={generateLink(RailsRoutes.bookings_slots_path(visit.property_slug))}>
          <Button label='Reprogrammer une visite' />
        </a>
      </div>
    </div>
  )
}

export default VisitCancelSuccess;
